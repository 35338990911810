$theme-colors: (
    "primary": #4777f4,
);

@import "~bootstrap/scss/bootstrap";

body {
    font-family: 'Nunito', sans-serif;
}

.header, .navbar, .navbar-top {
    transition: all .2s ease;
}

.navbar {
    padding: .75rem 1rem;
}

.navbar-light .navbar-toggler {
    color: rgba(31, 45, 61, .6);
    border-color: transparent;
}

.navbar-toggler {
    font-size: 1.25rem;
    line-height: 1;
    padding: .25rem .75rem;
    border: 1px solid transparent;
    border-radius: .375rem;
    background-color: transparent;
}

.navbar-sticky.sticky {
    position: fixed;
    z-index: 1030;
    top: -100px;
    left: 0;
    width: 100%;
    transition: all .2s ease;
    transform: translateY(100px);
    border-top: 0;
    box-shadow: 0 1rem 3rem rgba(31, 45, 61, .13);
}

.navbar-sticky.sticky .navbar-brand img {
    height: 1.75rem;
}

.navbar-btn {
    font-weight: 600;
    text-align: center;
    vertical-align: middle;
    border-radius: 100px;
    padding: .5rem 1.25rem;
}

.feather {
    width: 1em;
    height: 1em;
    fill: none;
    stroke: currentcolor;
    stroke-width: 3px;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.btn-icon .btn-inner--text:not(:first-child) {
    margin-left: .5em;
}

.navbar-nav .nav-link {
    font-weight: 600 !important;
    line-height: 1.7;
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 138, 255, .9);
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, .4);
}

.navbar-sticky.sticky .navbar-nav .nav-link {
    padding-top: 0;
    padding-bottom: 0;
}

.template-img {
    display: block;
    width: 150px;
    height: auto;
    border: .5rem solid grey;
    margin: .5rem auto;
}

.template-img.active {
    border-color: green;
}

.items-header {
    display: none;
}

@media (min-width: 768px) {
    .items-header {
        display: table-header-group;
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-brand img {
        height: 2.3rem;
    }
}

@media (max-width: 991.98px) {
    .navbar-expand-lg .navbar-brand img {
        height: 1.75rem;
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-radius: 0;
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1rem;
        padding-left: 1rem;
    }
}

@keyframes slide-in {
    0% {
        opacity: 0;
        transform: translateY(-8rem);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 991.98px) {
    .navbar-expand-lg .navbar-collapse-overlay.show {
        transition: all .2s ease-in-out;
        transform: translateY(0);
        opacity: 1;
    }

    .navbar-expand-lg .navbar-collapse-overlay {
        animation: slide-in .3s;
        position: fixed;
        z-index: 100;
        top: 1rem;
        right: 1rem;
        left: 1rem;
        overflow-y: scroll;
        max-height: calc(100vh - 2rem) !important;
        padding: 1rem 0;
        transition: all .2s ease-in-out;
        transition-property: opacity, transform;
        transform: translateY(-100%);
        transform-origin: top center;
        opacity: 0;
        border-radius: .375rem;
        box-shadow: 0 1rem 3rem rgba(31, 45, 61, .13);
        -webkit-overflow-scrolling: touch;
    }

    .navbar-expand-lg .navbar-collapse {
        background: #fff;
    }

    .navbar-expand-lg .navbar-collapse .navbar-toggler {
        position: absolute;
        z-index: 100;
        top: 0;
        right: .5rem;
        color: rgba(31, 45, 61, .6);
    }

    .navbar-expand-lg .navbar-collapse-overlay .nav-item {
        padding: 0 1.5rem;
    }
}
